.invisible {
  min-width: 0 !important;
  width: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  overflow: hidden !important;
  display: none !important;
}

.text-underline {
  text-decoration: underline;
}

.label-spacing-0 {
  letter-spacing: 0rem;
}

.box-shadow-none {
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-start {
  align-items: flex-start;
}

.full-width {
  width: 100%;
}

.width-100 {
  width: 100%;
}

.width-37 {
  width: 37%;
}

.height-100 {
  height: 100%;
}

.text-align-end {
  text-align: end;
}

.display-none {
  display: none !important;
}

.display-flex {
  display: flex;
}

.text-wrap {
  text-wrap: wrap;
}

.color-black {
  color: black;
}

.display-block {
  display: block;
}

.padding-0 {
  padding: 0 !important;
}

.mt-15 {
  margin-top: 1.5rem;
}

.mb-15 {
  margin-bottom: 1.5rem;
}

.mb-25 {
  margin-bottom: 2.5rem;
}

.text-center {
  text-align: center;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 0.5rem;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.gap-4 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

// Most commonly used Tailwind-inspired classes

// Spacing

.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 0.3rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: 0.5rem;
}

.p-6 {
  padding: 1.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-16 {
  padding-left: 4rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

// Flexbox
.flex {
  display: flex;
}

.grid {
  display: grid;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-05 {
  flex: 0.5;
}

.flex-1 {
  flex: 1;
}

.flex-25 {
  flex: 0 1 calc(25% - 2rem);
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.justify-space {
  justify-content: space-between;
}

// Cursor
.cursor-auto {
  cursor: auto;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-move {
  cursor: move;
}

.cursor-default {
  cursor: default;
}

// Visibility
.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.invisible {
  visibility: hidden;
}

.float-right {
  float: right;
}

.font-normal {
  font-weight: normal;
}

.font-bold {
  font-weight: bold;
}

.word-break {
  word-break: break-all;
}

.justify-flex-start {
  justify-content: flex-start;
}

.font-light {
  font-weight: 300;
}

.font-size-14 {
  font-size: 0.875rem;
}
